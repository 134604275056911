<template>
<b-card :title="$t('checkbox_integration')">
  <integration />
</b-card>
</template>
<script>
import Integration from './common/integration'
export default {
  middleware: 'role:admin',
  layout: 'default',
  metaInfo () {
    return { title: this.$t('checkbox') }
  },
  components: {
    Integration
  }
}
</script>
