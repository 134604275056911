<template>
  <div class="row">
  <div class="col-12">
    <router-link :to="{ name: 'checkbox.create' }" class="btn btn-sm btn-success"><fa icon="plus"/></router-link>
    <table class="table mt-2">
      <thead>
        <tr>
          <th>{{ $t('name') }}</th>
          <th>{{ $t('status') }}</th>
          <th class="text-right">{{ $t('edit') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in items" :key="item.id">
          <td>{{ item.name }}</td>
          <td>
            <b-checkbox v-model="item.status" @change="changeStatus(item, $event)">
              <span v-if="item.status">Активно</span>
              <span v-else>Відключено</span>
            </b-checkbox>
          </td>
          <td class="text-right">
            <div class="btn-group">
              <router-link :to="{ name: 'checkbox.edit', params: { id: item.id } }" class="btn btn-sm btn-warning">{{ $t('edit') }}</router-link>
              <button type="button" class="btn btn-sm btn-danger" @click="remove(item.id)"><fa icon="times" /></button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
</template>
<script>
import axios from 'axios'
export default {
  data: () => ({
    items: null
  }),
  mounted () {
    this.getData()
  },
  methods: {
    async getData () {
      const apiUrl = process.env.VUE_APP_ENDPOINT_V1
      await axios.get(apiUrl + 'checkbox/integrations').then(resp => {
        if (resp && resp.data) {
          this.items = resp.data
        }
      })
    },
    async changeStatus (item, checked) {
      const apiUrl = process.env.VUE_APP_ENDPOINT_V1
      await axios.post(apiUrl + 'checkbox/integration/status', { status: checked, id: item.id }).then(resp => {
        if (resp.data.status) {
          this.getData()
        }
      })
    },
    async remove (id) {
      const apiUrl = process.env.VUE_APP_ENDPOINT_V1
      await axios.delete(apiUrl + 'checkbox/integration/' + id).then(resp => {
        if (resp.data.status) {
          this.getData()
        }
      })
    }
  }
}
</script>
